import '@/styles/globals.css';
import domtoimage from 'dom-to-image';

import { Card, CardContent } from "@/components/ui/card"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Button } from "@/components/ui/button"
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area"

import { useState, useEffect, useRef } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetTrigger,
} from "@/components/ui/sheet"

type AvatarPart = 'body' | 'eyes' | 'mouth' | 'hair' | 'special'
type AvatarSelection = Record<AvatarPart, number>

const avatarParts = {
  body: [
    '/images/body/body01.png',
    '/images/body/body02.png',
    '/images/body/body03.png',
    '/images/body/body04.png',
    '/images/body/body05.png',
    '/images/body/body06.png',
    '/images/body/body07.png',
    '/images/body/body08.png',
    '/images/body/body09.png',
  ],
  eyes: [
    '/images/eyes/eyes01.png',
    '/images/eyes/eyes02.png',
    '/images/eyes/eyes03.png',
    '/images/eyes/eyes04.png',
    '/images/eyes/eyes05.png',
    '/images/eyes/eyes06.png',
    '/images/eyes/eyes07.png',
    '/images/eyes/eyes08.png',
    '/images/eyes/eyes09.png',
    '/images/eyes/eyes10.png',
  ],
  mouth: [
    '/images/mouth/mouth01.png',
    '/images/mouth/mouth02.png',
    '/images/mouth/mouth03.png',
    '/images/mouth/mouth04.png',
    '/images/mouth/mouth05.png',
    '/images/mouth/mouth06.png',
    '/images/mouth/mouth07.png',
    '/images/mouth/mouth08.png',
    '/images/mouth/mouth09.png',
    '/images/mouth/mouth10.png',
  ],
  hair: [
    '/images/hair/hair01.png',
    '/images/hair/hair02.png',
    '/images/hair/hair03.png',
    '/images/hair/hair04.png',
    '/images/hair/hair05.png',
    '/images/hair/hair06.png',
    '/images/hair/hair07.png',
    '/images/hair/hair08.png',
    '/images/hair/hair09.png',
    '/images/hair/hair10.png',
    '/images/hair/hair11.png',
    '/images/hair/hair12.png',
  ],
  special: [
    '/images/special/special01.png',
    '/images/special/special02.png',
    '/images/special/special03.png',
    '/images/special/special04.png',
    '/images/special/special05.png',
    '/images/special/special06.png',
    '/images/special/special07.png',
  ],
};


function App() {
  const [selection, setSelection] = useState<AvatarSelection>({
    body: 0,
    hair: 4,
    eyes: 0,
    mouth: 0,
    special: 0,
  })

  const [activeTab, setActiveTab] = useState<AvatarPart>('body');
  const [isTabChange, setIsTabChange] = useState(false);
  const selectedButtonRef = useRef<HTMLButtonElement>(null);

  const handleSelect = (part: AvatarPart, index: number) => {
    setSelection(prev => ({ ...prev, [part]: index }))
    setIsTabChange(false);
  }

  const handleTabChange = (value: string) => {
    setActiveTab(value as AvatarPart);
    setIsTabChange(true);
  };

  const handleRandomize = () => {
    const randomSelection: AvatarSelection = {
      body: 0,
      hair: 0,
      mouth: 0,
      eyes: 0,
      special: 0,
    };

    (Object.keys(avatarParts) as AvatarPart[]).forEach((part) => {
      let newIndex;
      do {
        newIndex = Math.floor(Math.random() * avatarParts[part].length);
      } while (newIndex === selection[part] && avatarParts[part].length > 1);
      randomSelection[part] = newIndex;
    });

    setSelection(randomSelection);
  }

  const handleDownload = async () => {
    const avatarElement = document.getElementById('avatar-container');
    if (avatarElement) {
      try {
        // Get the displayed size of the avatar container
        const rect = avatarElement.getBoundingClientRect();
        const displayWidth = rect.width;
        const displayHeight = rect.height;

        // Capture the avatar container at its displayed size
        const dataUrl = await domtoimage.toPng(avatarElement, {
          quality: 1,
          width: displayWidth,
          height: displayHeight,
          style: {
            transformOrigin: 'left top',
            width: `${displayWidth}px`,
            height: `${displayHeight}px`
          }
        });

        // Create a link element and trigger the download
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'my-avatar.png';
        link.click();
      } catch (error) {
        console.error('Error capturing avatar:', error);
      }
    }
  };

  useEffect(() => {
    if (selectedButtonRef.current) {
      selectedButtonRef.current.scrollIntoView({
        behavior: isTabChange ? 'auto' : 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [activeTab, selection, isTabChange]);

  useEffect(() => {
    const preventDrag = (e: DragEvent) => {
      e.preventDefault();
      return false;
    };

    document.addEventListener('dragstart', preventDrag);

    // Cleanup
    return () => {
      document.removeEventListener('dragstart', preventDrag);
    };
  }, []);

  const renderIcon = (name: string, alt: string) => (
    <img 
      src={`/ui/${name}.svg`}
      width={32} 
      height={32} 
      alt={alt} 
      title={alt}
      className="text-current"
      style={{ 
        filter: activeTab === name ? 'brightness(0) saturate(100%) invert(1)' : 'invert(0)',
        transition: 'filter 0.3s ease'
      }}
    />
  );

  return (
    <div className="h-screen flex flex-col bg-[#AAD2CA] overflow-hidden">
      <div className="flex-grow flex items-center justify-center p-4 relative h-screen overflow-y-hidden">
        <div className="flex justify-between items-start absolute top-4 left-4 right-4 w-[calc(100%-32px)]">
          <div className="flex-1">
            <Sheet>
              <SheetTrigger asChild>
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  className="inline-flex"
                >
                  <Button variant="destructive" className="px-5">
                  <img src="/ui/menu.svg" alt="Menu" className="w-8 h-8" />
                    <span className="sr-only">Open Menu</span>
                  </Button>
                </motion.div>
              </SheetTrigger>
              <SheetContent side="left">
              <ScrollArea className="h-full">
                <SheetDescription className="text-sm md:text-base text-[#12333C] leading-6 space-y-4">
                  <div className="flex justify-center mb-12">
                    <motion.img 
                      src="/ui/logo.svg" 
                      alt="Dazavatars" 
                      className="w-auto h-auto md:-translate-x-3"
                      animate={
                        typeof window !== 'undefined' && 
                        !window.matchMedia('(prefers-reduced-motion: reduce)').matches
                          ? {
                              rotate: [-2, 2, -2],
                              transition: {
                                duration: 2,
                                ease: "easeInOut",
                                repeat: Infinity,
                              },
                            }
                          : {}
                      }
                    />
                  </div>
                  <img 
                      src="/ui/title1.svg" 
                      alt="What?" 
                      className="w-auto h-auto"
                    />
                  <p>Make your own <strong>pixel dazavatars</strong>!</p>
                  <p>You can use the avatars made with <strong>Dazavatar</strong> in your games, apps, or anywhere else! Use them in personal, educational, or commercial projects, excluding non-fungible token (NFT) or blockchain projects.</p>
                  <img 
                      src="/ui/title2.svg" 
                      alt="Using dazavatars?" 
                      className="w-auto h-auto pt-8"
                    />
                    <p>I would love to see what are you using these avatars for! E-mail me at hi@dazlog.com.</p>
                  <img 
                      src="/ui/title3.svg" 
                      alt="Thanks!" 
                      className="w-auto h-auto pt-8"
                    />
                    <p>Using Dazavatar is <strong>completely free</strong>, but if you’d like to help me, you can follow me or donate:</p>
                  <div className="flex flex-col md:flex-row gap-3 pt-8 justify-center items-center">
                      <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      className="inline-flex"
                    >
                      <Button 
                        variant="ghost" 
                        className="border-[#4D9BE6]"
                        asChild
                      >
                        <a 
                          href="https://twitter.com/dazlog" 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          Twitter / X
                        </a>
                      </Button>
                    </motion.div>
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      className="inline-flex"
                    >
                      <Button 
                        variant="ghost" 
                        className="border-[#FB6B1D]"
                        asChild
                      >
                        <a 
                          href="https://patreon.com/Dazlog" 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          Patreon
                        </a>
                      </Button>
                    </motion.div>
                    <motion.div
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      transition={{ type: "spring", stiffness: 400, damping: 17 }}
                      className="inline-flex"
                    >
                      <Button 
                        variant="ghost" 
                        className="border-[#245460]"
                        asChild
                      >
                        <a 
                          href="https://mastodon.gamedev.place/@dazlog" 
                          target="_blank" 
                          rel="noopener noreferrer"
                        >
                          Mastodon
                        </a>
                      </Button>
                    </motion.div>
                  </div>
                  </SheetDescription>
                  </ScrollArea>
            </SheetContent>
            </Sheet>
          </div>
          <div className="flex flex-row gap-2 space-x-2">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className="inline-flex justify-end"
            >
              <Button onClick={handleRandomize} variant="destructive" className="px-5 md:pr-6">
                <img src="/ui/random.svg" alt="Randomize" className="w-8 h-8 md:mr-2" />
                <div className="md:block hidden">Randomize</div>
                <span className="sr-only">Randomize Avatar</span>
              </Button>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ type: "spring", stiffness: 400, damping: 17 }}
              className="flex justify-end"
            >
              <Button onClick={handleDownload} variant="secondary" className="px-5 md:pr-6">
                <img src="/ui/download.svg" alt="Download" className="w-8 h-8 md:mr-2" />
                <span className="md:block hidden">Download</span>
                <span className="sr-only">Download Avatar</span>
              </Button>
            </motion.div>
          </div>
        </div>
        <div className="flex justify-center items-center relative rounded-[48px] w-64 h-64 p-6 -translate-y-24 md:-translate-y-20 bg-[#F8F6E7] shadow-container">
          <div id="avatar-container" className="w-12 h-12 scale-[4] transform-gpu origin-center">
            {(Object.keys(avatarParts) as AvatarPart[]).map((part) => (
              <AnimatePresence mode="wait" key={part}>
                <motion.img
                  key={`${part}-${selection[part]}`}
                  src={avatarParts[part][selection[part]]}
                  alt={part}
                  className="absolute top-1 left-0 w-full h-full object-contain noDrag"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  transition={{ duration: 0.05 }}
                />
              </AnimatePresence>
            ))}
          </div>
        </div>
      </div>
      <div className="fixed md:bottom-12 bottom-4 left-1/2 transform -translate-x-1/2 max-w-3xl w-[calc(100%-24px)] mx-auto rounded-[32px] overflow-hidden shadow-container">
        <Card className="bg-[#F8F6E7] rounded-none border-none">
          <CardContent className="p-0">
          <div className="flex justify-between items-center">
              <Tabs defaultValue="body" className="w-full" onValueChange={handleTabChange}>
              <TabsList className="grid w-full grid-cols-5">
                <TabsTrigger value="body">
                  {renderIcon('body', 'Body')}
                </TabsTrigger>
                <TabsTrigger value="hair">
                  {renderIcon('hair', 'Hair')}
                </TabsTrigger>
                <TabsTrigger value="eyes">
                  {renderIcon('eyes', 'Eyes')}
                </TabsTrigger>
                <TabsTrigger value="mouth">
                  {renderIcon('mouth', 'Mouth')}
                </TabsTrigger>
                <TabsTrigger value="special">
                  {renderIcon('special', 'Special')}
                </TabsTrigger>
              </TabsList>
              {(Object.keys(avatarParts) as AvatarPart[]).map((part) => (
                <TabsContent key={part} value={part}>
                  <ScrollArea className="w-full whitespace-nowrap" style={{ overflow: 'auto' }}>
                    <div className="flex space-x-4 p-4" style={{ width: 'max-content' }}>
                      {avatarParts[part].map((src, index) => (
                        <motion.div
                        key={index}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.9 }}
                        tabIndex={-1} // Make this div not selectable with keyboard
                      >
                        <Button
                          key={index}
                          ref={selection[part] === index ? selectedButtonRef : null}
                          variant={selection[part] === index ? "default" : "outline"}
                          className="h-auto aspect-square flex-shrink-0 p-2 md:p-3 lg:p-4"
                          onClick={() => handleSelect(part, index)}
                        >
                          <img src={src} alt={`${part} ${index + 1}`} className="w-16 h-16 sm:w-20 sm:h-20 md:w-24 md:h-24 object-cover noDrag" />
                        </Button>
                        </motion.div>
                      ))}
                    </div>
                    <ScrollBar orientation="horizontal" className="px-6" />
                  </ScrollArea>
                </TabsContent>
              ))}
            </Tabs>
          </div>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default App;
